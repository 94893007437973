:root {
  --primary-color: #eac408;
  --font-color: #6b7280;
  --bg-color: #020617;
  --bg-color-2: #111827;
  --normal-font: 16px;
  --error:#a61919;
  --white:#FFFFFF;
  --black:#000000;
}

/* Light theme */
.light-theme {
  --primary-color: #eac408;
  --font-color: #374151;
  --bg-color: #ffffff;
  --bg-color-2: #f3f4f6;
  --error: #dc2626;
  --black:#FFFFFF;
  --white:#000000;
}

/* Dark theme */
.dark-theme {
  --primary-color: #eac408;
  --font-color: #d1d5db;
  --bg-color: #020617;
  --bg-color-2: #111827;
  --error: #a61919;
  --white:#FFFFFF;
  --black:#000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: var(--normal-font);
  line-height: 27px;
  color: var(--font-color);
}

Link,
NavLink,
a {
  text-decoration: none !important;
  color: var(--font-color);
}

.color-primary {
  color: var(--primary-color) !important;
}

.color-white {
  color: var(--white) !important;
}

.color-font {
  color: var(--font-color) !important;
}

.heading-font {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 64px;
  color: var(--white);
}

.small-heading-font {
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 64px;
  color: var(--white);
}

.but {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  gap: 10px;
  width: fit-content;
  height: 43px;
  background: var(--primary-color);
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: black;
  transition: 0.3s;
}

.but:hover{
  background-color: var(--font-color);
  color: var(--white);
}

input , textarea{
  padding: 10px 10px;
  border: none;
  outline: none;
}

.error-message{
  font-family: "Lexend Deca";
  font-style: normal;
  font-weight: 500;
  font-size: var(--normal-font);
  line-height: 20px;
  color: var(--error);
  margin-top: 4px;
  margin-left: 5px;
}


body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: var(--bg-color);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
  transition: 0.3s;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--white);
  cursor: pointer;
}


ul{
  list-style-position: outside;
  margin-left: -18px;
}